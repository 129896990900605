import L from "lodash/fp";
import { sequencep, purep, reject } from "bluebird-promisell";
import UserModel from "scripts/models/userModel";
import { fetchPosition } from "scripts/utils/geoHelpers";
import { getUrlParameter, removeUrlParameter, getHostname } from "scripts/utils/urlUtil";
import { nullP, pass, propEq } from "scripts/utils/generalHelpers";

import {
  fetchOrganizationUserFromSecurity,
  fetchOrganizationUserUsingPatronCredentialsFromSecurity,
  fetchUserForTokenFromSecurity,
  fetchUserWithUsernameAndPasswordFromSecurity,
} from "scripts/utils/fetchSecurity";

import {
  fetchOrganizationProfilesFromApi,
  fetchOrganizationsWithAccessToMediaFromApi,
  fetchCanOrganizationAccessMediaFromApi,
  fetchUserProfileFromApi,
} from "scripts/utils/fetchApi";
import { fetchUserFromSecurity } from "./fetchSecurity";

export const USE_LOCATION_FOR_ORG_AUTH = process.env.PATRON_UI_USE_LOCATION_FOR_ORG_AUTH === "true";

const hasCredentialType = propEq("credentialType");

export const ORGANIZATION_CREDENTIAL_TYPES = [
  "PASSWORD",
  "REDEMPTION_CODE",
  "REGION_PLUS",
  "SIGNED_URL",
  "TRUSTED_PARTNER",
  "REFERER",
  "IP",
  "SHIBBOLETH",
  "LAT_LONG",
  "REGION",
  "SCOPE",
  "BUYING_GROUP_REGION_PLUS",
  "BUYING_GROUP_SIGNED_URL",
  "BUYING_GROUP_TRUSTED_PARTNER",
  "BUYING_GROUP_REFERER",
  "BUYING_GROUP_IP",
  "BUYING_GROUP_LAT_LONG",
  "BUYING_GROUP_REGION",
  "BUYING_GROUP_SCOPE",
  "PUBLIC_ACCESS",
];

const mapSecurityUser = securityUser => {
  const props = L.pick(
    ["forcePasswordReset", "userId", "username", "emailAddress", "emailAddressVerified"],
    securityUser,
  );

  const roles = L.map(L.prop("roleName"), securityUser.roles);

  return {
    roles,
    ...props,
  };
};

const getCredentialTypeForPrincipal = principal =>
  L.contains("ROLE_BUYING_GROUP", principal.roles) &&
  principal.credentialType !== "PASSWORD" &&
  principal.credentialType !== "PUBLIC_ACCESS"
    ? `BUYING_GROUP_${principal.credentialType}`
    : principal.credentialType;

const getMembersForPrincipal = (principal, buyingGroups) =>
  L.compose(L.prop("members"), L.find(propEq("name", principal.name)))(buyingGroups);

const getMemberOfForPrincipal = (principal, buyingGroups) =>
  L.compose(L.prop("name"), L.find(L.compose(L.contains(principal.name), L.prop("members"))))(buyingGroups);

const mapSecurityPrincipals = principals => {
  const buyingGroupPrincipals = L.filter(propEq("type", "BUYING_GROUP"), principals);

  const organizations = L.compose(
    L.map(p => ({
      organizationId: p.name,
      roles: p.roles,
      credentialType: getCredentialTypeForPrincipal(p),
      isPublic: p.public,
      alias: p.alias,
      priority: p.priority,
      members: getMembersForPrincipal(p, buyingGroupPrincipals),
      memberOf: getMemberOfForPrincipal(p, buyingGroupPrincipals),
    })),
    L.filter(propEq("type", "ORGANIZATION")),
  )(principals);

  const pressbooksApps = L.compose(
    L.filter(L.prop("applicationUrl")),
    L.prop("organizationAccess"),
    L.find(L.allPass([propEq("type", "APPLICATION"), propEq("alias", "Pressbooks")])),
  )(principals);

  return L.map(organization => {
    const pressbooks = L.find(propEq("organizationId", organization.organizationId), pressbooksApps);

    return pressbooks
      ? {
          ...organization,
          pressbooksUrl: pressbooks.applicationUrl,
        }
      : organization;
  }, organizations);
};

const getOrganizationIdsFromPrincipals = L.compose(
  L.map(o => o.organizationId),
  mapSecurityPrincipals,
);

const mapApiUserProfile = L.pick(["firstName", "lastName", "contentView"]);

const mapApiOrganization = L.pick([
  "name",
  "brandingLogoUrl",
  "message",
  "showSelfe",
  "bannerAdMobileApp",
  "showBuyLink",
  "selfeEnabled",
  "oauthDisplayName",
  "marketingUrl",
  "marketingUrlName",
  "libraryCardUrl",
  "showShareLinks",
  "organizationProperties",
  "attributionStmt",
]);

const fetchAdditionalInfoFromApi = subject => {
  const { token, principals } = subject;
  const userPrincipal = L.find(propEq("type", "USER"), principals);

  const apiFetches = [fetchOrganizationProfilesFromApi(token, getOrganizationIdsFromPrincipals(principals))];

  if (userPrincipal) {
    apiFetches.push(fetchUserProfileFromApi(token, userPrincipal.name));
    apiFetches.push(fetchUserFromSecurity(token, userPrincipal.name));
  }

  return sequencep(apiFetches).then(([apiOrganizations, apiUser, securityUser]) => [
    subject,
    apiOrganizations,
    apiUser,
    securityUser,
  ]);
};

const mergeApiOrganizations = (apiOrganizations, organizations) =>
  L.map(
    organization =>
      L.merge(mapApiOrganization(L.find(propEq("id", organization.organizationId), apiOrganizations)), organization),
    organizations,
  );

const joinAuthInfo = ([subject, apiOrganizations, apiUser, securityUser]) => {
  const obj = {
    token: subject.token,
    organizations: mergeApiOrganizations(apiOrganizations, mapSecurityPrincipals(subject.principals)),
  };

  if (!L.isNil(subject.bot)) {
    obj.bot = subject.bot;
  }

  if (securityUser && apiUser) {
    obj.user = L.merge(mapSecurityUser(securityUser), mapApiUserProfile(apiUser.userProfile));
  }

  return obj;
};

const fetchAdditionalApiInfoAndCreateUser = subject =>
  fetchAdditionalInfoFromApi(subject)
    .then(joinAuthInfo)
    .then(user => new UserModel(user));

export const fetchUserForToken = props =>
  fetchUserForTokenFromSecurity(props).then(fetchAdditionalApiInfoAndCreateUser);

export const fetchOrganizationUser = props =>
  fetchOrganizationUserFromSecurity(props).then(fetchAdditionalApiInfoAndCreateUser);

export const fetchOrganizationUserUsingPatronCredentials = props =>
  fetchOrganizationUserUsingPatronCredentialsFromSecurity(props)
    .then(pass(props => console.log("props from security: %O", props)))
    .then(fetchAdditionalApiInfoAndCreateUser)
    .then(pass(props => console.log("props after api: %O", props)));

export const fetchUserWithUsernameAndPassword = props =>
  fetchUserWithUsernameAndPasswordFromSecurity(props).then(fetchAdditionalApiInfoAndCreateUser);

export const fetchUserJsonFromLocalStorage = authScope => {
  const userJson = localStorage.getItem("modelCookieStorage-user");
  const userAuthScope = userJson && userJson.authScope;

  return L.isNil(userJson) || ((authScope || userAuthScope) && authScope !== userAuthScope)
    ? nullP()
    : purep(JSON.parse(userJson));
};

export const fetchUserFromLocalStorage = authScope =>
  new UserModel()
    .fetch()
    .then(user => {
      const userAuthScope = user.get("authScope");

      if ((authScope || userAuthScope) && authScope !== userAuthScope) {
        throw new Error("Auth scope in local storage does not match required auth scope: %O", authScope);
      }

      return user;
    })
    .catch(() => {
      // Super common misleading error
      // console.log('Error fetching user from local storage: %O', e);
      return new UserModel();
    });

export const fetchOrganizationTokenFromLocalStorage = ({ authScope }) =>
  fetchUserFromLocalStorage(authScope).then(user => {
    if (!user.hasProfile()) {
      return user.getToken();
    } else {
      return null;
    }
  });
// .catch(() => null);

export const removeUserFromLocalStorage = () => new UserModel().destroy();

export const getTokenFromUrl = url => {
  const getParam = L.partialRight(getUrlParameter, [url]);

  if (getParam("custId") && getParam("partnerId")) {
    return null;
  }

  return getParam("token");
};

export const getCoordsFromUrl = url => {
  const getParam = L.partialRight(getUrlParameter, [url]);

  const lat = getParam("lat");
  const long = getParam("long");

  if (lat && long) {
    return {
      latitude: parseFloat(lat),
      longitude: parseFloat(long),
    };
  } else {
    return null;
  }
};

export const removeCoordsFromUrl = L.compose(removeUrlParameter("lat"), removeUrlParameter("long"));

// export const getPatronIdFromUrlParam = getUrlParameter('patronId');

export const getAuthScopeFromSubdomain = url => {
  const hostname = getHostname(url);
  const regex = /^(.*)\.(localhost|biblioboard\.com|openresearchlibrary.org)$/;
  const results = regex.exec(hostname);

  if (results) {
    const subdomain = results[1];

    if (subdomain && !subdomain.startsWith("library") && !subdomain.startsWith("patron")) {
      return subdomain;
    }
  }

  return null;
};

export const getAuthDomainScope = url => {
  const hostname = getHostname(url);
  const regex = /^(.*\.)?(.*)\.(?!localhost)/;
  const results = regex.exec(hostname);

  console.log(results);
  if (results) {
    const domain = results[2];
    if (domain) {
      return domain;
    }
  }

  return "localhost";
};

export const getRefererFromUrlParam = getUrlParameter("referer");

export const getRefererRouteParameter = url => {
  const results = /\/referralUrl:(.*)$/.exec(url);

  if (results && results[1]) {
    return decodeURIComponent(results[1]);
  }
};

export const removeRefererRouteParameter = url => url.replace(/\/referralUrl:(.*)$/, "");

export const getOrganizationsWithHighestCredentialType = (
  organizations,
  credentialTypes = ORGANIZATION_CREDENTIAL_TYPES,
) => {
  if (credentialTypes.length > 0) {
    const orgs = L.filter(hasCredentialType(L.head(credentialTypes)), organizations);

    if (orgs.length) {
      return orgs;
    } else {
      return getOrganizationsWithHighestCredentialType(organizations, L.tail(credentialTypes));
    }
  } else {
    return [];
  }
};

export const getOrganizationsWithHighestPrecedence = user =>
  getOrganizationsWithHighestCredentialType(user.getOrganizations(), ORGANIZATION_CREDENTIAL_TYPES);

export const getDefaultOrganizationForUser = user => {
  console.log("USER: ", user);
  if (user.hasProfile()) {
    return L.head(user.getOrganizations());
  } else {
    const orgs = getOrganizationsWithHighestPrecedence(user);
    if (orgs.length === 1) {
      return L.head(orgs);
    } else {
      const priorityOrgs = L.filter(L.prop("priority"), orgs);

      return priorityOrgs.length === 1 ? L.head(priorityOrgs) : void 0;
    }
  }
};

export const fetchGeoCoords = geolocation =>
  fetchPosition(geolocation).then(locationInfo =>
    locationInfo && locationInfo.position && locationInfo.position.coords
      ? {
          latitude: locationInfo.position.coords.latitude,
          longitude: locationInfo.position.coords.longitude,
        }
      : reject(new Error("Invalid location info")),
  );

export const fetchOrganizationsWithAccessToMedia = (mediaId, user) =>
  fetchOrganizationsWithAccessToMediaFromApi(user.getToken(), mediaId, user.getOrganizations());

export const fetchCanOrganizationAccessMedia = (user, organizationId, mediaId) =>
  fetchCanOrganizationAccessMediaFromApi(user.getToken(), mediaId, organizationId);

export const collateOrganizationsByPrecedence = (organizations, credentialTypes, collated = []) => {
  if (L.isEmpty(credentialTypes)) {
    return collated;
  } else {
    const credentialType = L.head(credentialTypes);
    const organizationsWithCredentialType = L.filter(hasCredentialType(credentialType), organizations);
    const remainingOrganizations = L.filter(L.complement(hasCredentialType(credentialType)), organizations);
    return collateOrganizationsByPrecedence(
      remainingOrganizations,
      L.tail(credentialTypes),
      L.concat(collated, [organizationsWithCredentialType]),
    );
  }
};

export const isLegacyUser = userJson => !L.isNil(userJson) && userJson.bannerAdMobileApp !== void 0;

export const isMigratedUser = userJson => !L.isNil(userJson) && userJson.migrated;

export const migrateUserJson = old => ({
  migrated: true,
  id: old.id,
  preferences: {
    contentView: old.preferences.contentView,
  },
  token: old.token,
  organizations: [
    {
      name: old.organizationName,
      brandingLogoUrl: old.organizationBrandingLogoUrl,
      message: "",
      showSelfe: old.showSelfe,
      bannerAdMobileApp: old.bannerAdMobileApp,
      showBuyLink: old.showBuyLinks,
      selfeEnabled: old.selfEEnabled,
      organizationId: old.organizationId,
      roles: ["ROLE_ORGANIZATION"],
      credentialType: "PASSWORD",
      isPublic: false,
      showShareLinks: old.showShareLinks,
    },
  ],
  user: {
    roles: old.roles,
    forcePasswordReset: false,
    userId: old.userId,
    username: old.preferences.username,
    emailAddress: old.preferences.emailAddress,
    emailAddressVerified: old.preferences.emailAddressVerified,
    firstName: old.preferences.firstName,
    lastName: old.preferences.lastName,
    contentView: old.preferences.contentView,
  },
});
