var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":7,"column":22},"end":{"line":7,"column":44}}}) : helper)))
    + "/"
    + alias4((lookupProperty(helpers,"getMediaId")||(depth0 && lookupProperty(depth0,"getMediaId"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),(depth0 != null ? lookupProperty(depth0,"internalCode") : depth0),{"name":"getMediaId","hash":{},"data":data,"loc":{"start":{"line":7,"column":45},"end":{"line":7,"column":75}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":7,"column":76},"end":{"line":7,"column":96}}}) : helper)))
    + "/highlights?sort="
    + alias4(((helper = (helper = lookupProperty(helpers,"contentSort") || (depth0 != null ? lookupProperty(depth0,"contentSort") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contentSort","hash":{},"data":data,"loc":{"start":{"line":7,"column":113},"end":{"line":7,"column":128}}}) : helper)))
    + "\" class=\"content-filter\"\n               aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"highlights") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"highlights") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":12,"column":22},"end":{"line":12,"column":44}}}) : helper)))
    + "/"
    + alias4((lookupProperty(helpers,"getMediaId")||(depth0 && lookupProperty(depth0,"getMediaId"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),(depth0 != null ? lookupProperty(depth0,"internalCode") : depth0),{"name":"getMediaId","hash":{},"data":data,"loc":{"start":{"line":12,"column":45},"end":{"line":12,"column":75}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":12,"column":76},"end":{"line":12,"column":96}}}) : helper)))
    + "/articles?sort="
    + alias4(((helper = (helper = lookupProperty(helpers,"contentSort") || (depth0 != null ? lookupProperty(depth0,"contentSort") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contentSort","hash":{},"data":data,"loc":{"start":{"line":12,"column":111},"end":{"line":12,"column":126}}}) : helper)))
    + "\" class=\"content-filter\"\n               aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"articles") : stack1)) != null ? lookupProperty(stack1,"arialabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"articles") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":17,"column":22},"end":{"line":17,"column":44}}}) : helper)))
    + "/"
    + alias4((lookupProperty(helpers,"getMediaId")||(depth0 && lookupProperty(depth0,"getMediaId"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),(depth0 != null ? lookupProperty(depth0,"internalCode") : depth0),{"name":"getMediaId","hash":{},"data":data,"loc":{"start":{"line":17,"column":45},"end":{"line":17,"column":75}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":17,"column":76},"end":{"line":17,"column":96}}}) : helper)))
    + "/books?sort="
    + alias4(((helper = (helper = lookupProperty(helpers,"contentSort") || (depth0 != null ? lookupProperty(depth0,"contentSort") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contentSort","hash":{},"data":data,"loc":{"start":{"line":17,"column":108},"end":{"line":17,"column":123}}}) : helper)))
    + "\" class=\"content-filter\"\n               aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"books") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"books") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":22,"column":22},"end":{"line":22,"column":44}}}) : helper)))
    + "/"
    + alias4((lookupProperty(helpers,"getMediaId")||(depth0 && lookupProperty(depth0,"getMediaId"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),(depth0 != null ? lookupProperty(depth0,"internalCode") : depth0),{"name":"getMediaId","hash":{},"data":data,"loc":{"start":{"line":22,"column":45},"end":{"line":22,"column":75}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":22,"column":76},"end":{"line":22,"column":96}}}) : helper)))
    + "/chapters?sort="
    + alias4(((helper = (helper = lookupProperty(helpers,"contentSort") || (depth0 != null ? lookupProperty(depth0,"contentSort") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contentSort","hash":{},"data":data,"loc":{"start":{"line":22,"column":111},"end":{"line":22,"column":126}}}) : helper)))
    + "\" class=\"content-filter\"\n               aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"chapters") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"chapters") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":27,"column":22},"end":{"line":27,"column":44}}}) : helper)))
    + "/"
    + alias4((lookupProperty(helpers,"getMediaId")||(depth0 && lookupProperty(depth0,"getMediaId"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),(depth0 != null ? lookupProperty(depth0,"internalCode") : depth0),{"name":"getMediaId","hash":{},"data":data,"loc":{"start":{"line":27,"column":45},"end":{"line":27,"column":75}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":27,"column":76},"end":{"line":27,"column":96}}}) : helper)))
    + "/other-documents?sort="
    + alias4(((helper = (helper = lookupProperty(helpers,"contentSort") || (depth0 != null ? lookupProperty(depth0,"contentSort") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contentSort","hash":{},"data":data,"loc":{"start":{"line":27,"column":118},"end":{"line":27,"column":133}}}) : helper)))
    + "\" class=\"content-filter\"\n               aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"documents") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"documents") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":32,"column":22},"end":{"line":32,"column":44}}}) : helper)))
    + "/"
    + alias4((lookupProperty(helpers,"getMediaId")||(depth0 && lookupProperty(depth0,"getMediaId"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),(depth0 != null ? lookupProperty(depth0,"internalCode") : depth0),{"name":"getMediaId","hash":{},"data":data,"loc":{"start":{"line":32,"column":45},"end":{"line":32,"column":75}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":32,"column":76},"end":{"line":32,"column":96}}}) : helper)))
    + "/images?sort="
    + alias4(((helper = (helper = lookupProperty(helpers,"contentSort") || (depth0 != null ? lookupProperty(depth0,"contentSort") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contentSort","hash":{},"data":data,"loc":{"start":{"line":32,"column":109},"end":{"line":32,"column":124}}}) : helper)))
    + "\" class=\"content-filter\"\n               aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"images") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"images") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":37,"column":22},"end":{"line":37,"column":44}}}) : helper)))
    + "/"
    + alias4((lookupProperty(helpers,"getMediaId")||(depth0 && lookupProperty(depth0,"getMediaId"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),(depth0 != null ? lookupProperty(depth0,"internalCode") : depth0),{"name":"getMediaId","hash":{},"data":data,"loc":{"start":{"line":37,"column":45},"end":{"line":37,"column":75}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":37,"column":76},"end":{"line":37,"column":96}}}) : helper)))
    + "/audio?sort="
    + alias4(((helper = (helper = lookupProperty(helpers,"contentSort") || (depth0 != null ? lookupProperty(depth0,"contentSort") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contentSort","hash":{},"data":data,"loc":{"start":{"line":37,"column":108},"end":{"line":37,"column":123}}}) : helper)))
    + "\" class=\"content-filter\"\n               aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"audio") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"audio") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":42,"column":22},"end":{"line":42,"column":44}}}) : helper)))
    + "/"
    + alias4((lookupProperty(helpers,"getMediaId")||(depth0 && lookupProperty(depth0,"getMediaId"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),(depth0 != null ? lookupProperty(depth0,"internalCode") : depth0),{"name":"getMediaId","hash":{},"data":data,"loc":{"start":{"line":42,"column":45},"end":{"line":42,"column":75}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":42,"column":76},"end":{"line":42,"column":96}}}) : helper)))
    + "/audiobooks?sort="
    + alias4(((helper = (helper = lookupProperty(helpers,"contentSort") || (depth0 != null ? lookupProperty(depth0,"contentSort") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contentSort","hash":{},"data":data,"loc":{"start":{"line":42,"column":113},"end":{"line":42,"column":128}}}) : helper)))
    + "\" class=\"content-filter\"\n               aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"audiobook") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"audiobook") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":47,"column":22},"end":{"line":47,"column":44}}}) : helper)))
    + "/"
    + alias4((lookupProperty(helpers,"getMediaId")||(depth0 && lookupProperty(depth0,"getMediaId"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),(depth0 != null ? lookupProperty(depth0,"internalCode") : depth0),{"name":"getMediaId","hash":{},"data":data,"loc":{"start":{"line":47,"column":45},"end":{"line":47,"column":75}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":47,"column":76},"end":{"line":47,"column":96}}}) : helper)))
    + "/videos?sort="
    + alias4(((helper = (helper = lookupProperty(helpers,"contentSort") || (depth0 != null ? lookupProperty(depth0,"contentSort") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contentSort","hash":{},"data":data,"loc":{"start":{"line":47,"column":109},"end":{"line":47,"column":124}}}) : helper)))
    + "\" class=\"content-filter\"\n               aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"videos") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"videos") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"container\">\n    <div class=\"row m-0 p-0 m-b-40\">\n        <a id=\"bb-anthology-collection-page-content-sub-page-all-content-link\" href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":3,"column":86},"end":{"line":3,"column":108}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":3,"column":109},"end":{"line":3,"column":115}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":3,"column":116},"end":{"line":3,"column":136}}}) : helper)))
    + "?sort="
    + alias4(((helper = (helper = lookupProperty(helpers,"contentSort") || (depth0 != null ? lookupProperty(depth0,"contentSort") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contentSort","hash":{},"data":data,"loc":{"start":{"line":3,"column":142},"end":{"line":3,"column":157}}}) : helper)))
    + "\" class=\"content-filter\"\n           aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"all") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"all") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"highlightCount") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":9,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"articleCount") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"bookCount") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":19,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"chapterCount") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"otherDocumentCount") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":29,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"imageCount") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":34,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"audioCount") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":8},"end":{"line":39,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"audiobookCount") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":44,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"videoCount") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":8},"end":{"line":49,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n\n<div id=\"bb-anthology-collection-page-content-sub-page-content-region\"></div>";
},"useData":true});