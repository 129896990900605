var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"defaultContentSort") : depth0),"collectionDisplayOrder",{"name":"equal","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":48},"end":{"line":47,"column":58}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                    <li><button value=\"collectionDisplayOrder\" class=\"sort-option btn-text "
    + ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"sortOption") : depth0),"collectionDisplayOrder",{"name":"equal","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":123},"end":{"line":44,"column":185}}})) != null ? stack1 : "")
    + "\">\n                                                        Default  <span class=\"m-l-20\"><i class=\"bbico bbico-check\"></i></span>\n                                                    </button></li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "active";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"content-details-nav\">\n    <div class=\"container\">\n        <div class=\"row-same-height row-full-height\">\n            <nav>\n                <div class=\"navigation-container\">\n                    <div class=\"tab-less-navigation-header\">\n                        <div class=\"navbar-header-container\">\n                            <div class=\"active-tab\">\n                                <div id=\"bb-content-details-page-nav-heading-details\" class=\"active\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"content") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</div>\n                            </div>\n\n                            <div class=\"collection-nav-right-container\">\n                                <div class=\"collection-view-toggle\">\n                                    <!--switch view type button-->\n                                    <a id=\"bb-secondary-sticky-nav-presentation-type-toggle-list\"\n                                    aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"list") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                                    class=\"fade-on-hover hide cntntmast-view-type-button\"\n                                    href=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"anthology") : depth0)) != null ? lookupProperty(stack1,"typeLabelLowerCase") : stack1), depth0))
    + "-"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":18,"column":76},"end":{"line":18,"column":98}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":18,"column":99},"end":{"line":18,"column":114}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyCollectionId") || (depth0 != null ? lookupProperty(depth0,"anthologyCollectionId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyCollectionId","hash":{},"data":data,"loc":{"start":{"line":18,"column":115},"end":{"line":18,"column":140}}}) : helper)))
    + "/list/"
    + alias2(((helper = (helper = lookupProperty(helpers,"contentType") || (depth0 != null ? lookupProperty(depth0,"contentType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"contentType","hash":{},"data":data,"loc":{"start":{"line":18,"column":146},"end":{"line":18,"column":161}}}) : helper)))
    + "\">\n                                        <div><i class=\"bbico bbico-list m-r-5\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"list") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</div>\n                                    </a>\n\n                                    <a id=\"bb-secondary-sticky-nav-presentation-type-toggle-masonry\"\n                                    aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"grid") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                                    class=\"fade-on-hover hide cntntmast-view-type-button\"\n                                    href=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"anthology") : depth0)) != null ? lookupProperty(stack1,"typeLabelLowerCase") : stack1), depth0))
    + "-"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":25,"column":76},"end":{"line":25,"column":98}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":25,"column":99},"end":{"line":25,"column":114}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyCollectionId") || (depth0 != null ? lookupProperty(depth0,"anthologyCollectionId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyCollectionId","hash":{},"data":data,"loc":{"start":{"line":25,"column":115},"end":{"line":25,"column":140}}}) : helper)))
    + "/masonry/"
    + alias2(((helper = (helper = lookupProperty(helpers,"contentType") || (depth0 != null ? lookupProperty(depth0,"contentType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"contentType","hash":{},"data":data,"loc":{"start":{"line":25,"column":149},"end":{"line":25,"column":164}}}) : helper)))
    + "\">\n                                        <div><i class=\"fa fa-th m-r-5\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"grid") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</div>\n                                    </a>\n                                </div>\n\n                                <div class=\"bb-media-page-menu-filter-region\">\n                                    <div class=\"btn-group\">\n                                        <button \n                                            type=\"button\" \n                                            class=\"btn btn-rounded btn-rounded-brand-primary dropdown-toggle\" \n                                            data-toggle=\"dropdown\" \n                                            aria-haspopup=\"true\" \n                                            aria-expanded=\"false\"\n                                        >\n                                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"sortBy") : stack1), depth0))
    + " <span><i class=\"fa fa-angle-down\"></i></span>\n                                        </button>\n                                        <ul class=\"dropdown-menu\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"defaultContentSort") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":44},"end":{"line":48,"column":51}}})) != null ? stack1 : "")
    + "                                            <li><button value=\"title asc\" class=\"sort-option btn-text "
    + ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"sortOption") : depth0),"title asc",{"name":"equal","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":102},"end":{"line":49,"column":151}}})) != null ? stack1 : "")
    + "\">\n                                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"name") : stack1)) != null ? lookupProperty(stack1,"asc") : stack1), depth0))
    + "  <span class=\"m-l-20\"><i class=\"bbico bbico-check\"></i></span>\n                                            </button></li>\n                                            <li><button value=\"title desc\" class=\"sort-option btn-text "
    + ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"sortOption") : depth0),"title desc",{"name":"equal","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":103},"end":{"line":52,"column":153}}})) != null ? stack1 : "")
    + "\">\n                                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"name") : stack1)) != null ? lookupProperty(stack1,"desc") : stack1), depth0))
    + "  <span class=\"m-l-20\"><i class=\"bbico bbico-check\"></i></span>\n                                            </button></li>\n                                            <li><button value=\"publicationDate asc\" class=\"sort-option btn-text "
    + ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"sortOption") : depth0),"publicationDate asc",{"name":"equal","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":112},"end":{"line":55,"column":171}}})) != null ? stack1 : "")
    + "\">\n                                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"publicationDate") : stack1)) != null ? lookupProperty(stack1,"desc") : stack1), depth0))
    + "  <span class=\"m-l-20\"><i class=\"bbico bbico-check\"></i></span>\n                                            </button></li>\n                                            <li><button value=\"publicationDate desc\" class=\"sort-option btn-text "
    + ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"sortOption") : depth0),"publicationDate desc",{"name":"equal","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":113},"end":{"line":58,"column":173}}})) != null ? stack1 : "")
    + "\">\n                                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"publicationDate") : stack1)) != null ? lookupProperty(stack1,"asc") : stack1), depth0))
    + "  <span class=\"m-l-20\"><i class=\"bbico bbico-check\"></i></span>\n                                            </button></li>\n                                            <li><button value=\"auditUpdated desc\" class=\"sort-option btn-text "
    + ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"sortOption") : depth0),"auditUpdated desc",{"name":"equal","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":110},"end":{"line":61,"column":167}}})) != null ? stack1 : "")
    + "\">\n                                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"updated") : stack1)) != null ? lookupProperty(stack1,"desc") : stack1), depth0))
    + "  <span class=\"m-l-20\"><i class=\"bbico bbico-check\"></i></span>\n                                            </button></li>\n                                        </ul>\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </nav>\n        </div>\n    </div>\n</div>\n";
},"useData":true});