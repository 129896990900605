import L from "lodash/fp";
import Backbone from "backbone";
import inject from "scripts/ioc/inject";
import BaseView from "scripts/views/baseView";
import { alert, noAuthScopeAccessAlert } from "scripts/alerts/alerts";
import EzProxyRedirectAlert from "scripts/alerts/ezProxyRedirectAlert";
import GenericLoginView from "scripts/views/authentication/genericLoginView";
import PatronVerificationModalView from "scripts/views/patronVerificationModalView";
import { offlineNotSignedInAlert } from "scripts/alerts/alerts";
import { redirectToFragment } from "scripts/utils/routerHelpers";
import { pass } from "scripts/utils/generalHelpers";
import { getUrlParameter } from "scripts/utils/urlUtil";

import template from "templates/loginPage.hbs";

const chan = Backbone.Radio.channel;

class LoginPageView extends BaseView {
  constructor(
    options,
    securityService = inject("securityService"),
    connectionService = inject("connectionService"),
    i18nextService = inject("i18nextService"),
  ) {
    super(options);

    this.options = options;

    this.securityService = securityService;
    this.connectionService = connectionService;
    this.i18nextService = i18nextService;

    this.loginView = this.addSubView(new GenericLoginView());
    this.loginView.on("createProfile", () => this.onCreateProfile(), this);

    this.state = {
      organizationVerification: {},
    };
  }

  render() {
    const glossary = this.i18nextService.getGlossary();
    const { needHelp, startExploring } = L.get(["auth"], glossary);
    const { diagnostics, support } = L.get(["app"], glossary);

    this.$el.html(
      template({
        i18n: {
          diagnostics: diagnostics.plural,
          needHelp,
          startExploring,
          support,
        },
      }),
    );

    this.loginView.appendTo(this.$el.find("#bb-login-page-login-region")).render();

    if (!this.connectionService.isOnline()) {
      offlineNotSignedInAlert.show();
    }

    return this;
  }

  onCreateProfile() {
    if (L.isNil(this.securityService.authScope)) {
      // This shouldn't be possible but we'll redirect anyway
      redirectToFragment("/sign-up/create-profile");
    } else {
      const { organizationId, organizationName, verification } = this.securityService.authScopeInfo;

      if (verification) {
        if (verification.proxyPrefix) {
          this.showEzProxyRedirectAlert(organizationId, organizationName, verification.proxyPrefix);
        } else {
          this.showPatronVerificationModal(organizationId, organizationName, verification.pinRequired);
        }
      } else {
        noAuthScopeAccessAlert.show();
      }
    }
  }

  showEzProxyRedirectAlert(organizationId, organizationName, proxyPrefix) {
    const authScopeParam = getUrlParameter("authScope", window.location.href);
    const qurlParams = {};

    if (authScopeParam) {
      qurlParams["authScope"] = authScopeParam;
    }

    const alert = new EzProxyRedirectAlert({
      organizationName,
      proxyPrefix,
      qurl: `${window.location.origin}/sign-up/create-profile`,
      qurlParams,
    });

    alert.show();
  }

  showPatronVerificationModal(organizationId, organizationName, pinRequired) {
    const modal = new PatronVerificationModalView({
      organizationId,
      organizationName,
      pinRequired,
    });

    modal.on("success", ({ organizationUser }) => {
      alert({
        title: "Validated!",
        text: `Welcome ${organizationName} member!`,
        type: "success",
        showCancelButton: false,
        confirmButtonText: "OK",
      }).show();

      organizationUser.setActiveOrganizationId(organizationId);

      this.securityService.setUser(organizationUser).then(
        pass(() => {
          redirectToFragment("/sign-up/create-profile");
        }),
      );
    });

    chan("display").request("showModal", modal);
  }
}

export default LoginPageView;
