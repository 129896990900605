import _ from "lodash";
import Promise from "bluebird";

// polyfills
import "@babel/polyfill";
import "scripts/polyfills/a-download"; // a modernizr polyfill
import "scripts/polyfills/console";
import "scripts/polyfills/window.location.origin";
import "scripts/polyfills/rAF";
import "scripts/polyfills/DragDropTouch";
import "scripts/polyfills/classListToggle";
import "focus-visible";

// jquery extensions
import "imagesloaded";
import "jquery.isinview";
import jqueryMouseWheel from "jquery-mousewheel";

import "form-serializer/lib/plugin";

import "velocity-animate";
import "velocity-animate/velocity.ui";

// lodash extensions
import underscoreString from "underscore.string";

// backbone extensions
import Backbone from "backbone";
import "backbone.radio";
import "scripts/libs/backbone.radio.extensions";
import "backbone.paginator";
import "backbone.localstorage";
import "scripts/libs/backbone.sessionStorage";
import "scripts/libs/backbone.cookieStorage";
import "scripts/libs/backbone.history.navigate.authscope";

// validate.js extensions
import "scripts/libs/customValidators";

// Handlebars helpers
import "scripts/handlebarsHelpers/versionHelper";
import "scripts/handlebarsHelpers/hasProfileHelper";
import "scripts/handlebarsHelpers/hasOfflineBookshelf";
import "scripts/handlebarsHelpers/addClassToSubstring";
import "scripts/handlebarsHelpers/pluralize";
import "scripts/handlebarsHelpers/itemAt";
import "scripts/handlebarsHelpers/formatBytes";
import "scripts/handlebarsHelpers/formatSeconds";
import "scripts/handlebarsHelpers/formatNumber";
import "scripts/handlebarsHelpers/currentYear";
import "scripts/handlebarsHelpers/featureFlag";
import "scripts/handlebarsHelpers/ternary";
import "scripts/handlebarsHelpers/processEnv";
import "scripts/handlebarsHelpers/equal";
import "scripts/handlebarsHelpers/notEqual";
import "scripts/handlebarsHelpers/bookViewerTocItems";
import "scripts/handlebarsHelpers/getMediaId";

jqueryMouseWheel($);

$.Velocity.Promise = Promise;

_.mixin(underscoreString.exports());

Backbone.$ = $;
