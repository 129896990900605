import L from "lodash/fp";
import Promise from "bluebird";
import Backbone from "backbone";
import ChangePasswordModalView from "scripts/views/changePasswordModalView";
import Controller from "scripts/controllers/controller";
import LoginPageView from "scripts/views/loginPageView";
import SignInModalView from "scripts/views/signInModalView";
import errorAlert from "scripts/alerts/errorAlert";
import inject from "scripts/ioc/inject";
import { alert } from "scripts/alerts/alerts";
import { getDefaultOrganizationForUser } from "scripts/utils/securityHelpers";
import { isProxyHostname, parseRedirectFragmentFromUrl } from "scripts/utils/urlUtil";
import { replaceFragment, refreshRoute, parseAppSchemeUrl } from "scripts/utils/routerHelpers";
import EzProxyRedirectAlert, { redirectPrefix } from "../alerts/ezProxyRedirectAlert";

const chan = Backbone.Radio.channel;

class SecurityController extends Controller {
  constructor(
    securityService = inject("securityService"),
    googleAnalyticsService = inject("googleAnalyticsService"),
    connectionService = inject("connectionService"),
  ) {
    super();

    this.securityService = securityService;
    this.googleAnalyticsService = googleAnalyticsService;
    this.connectionService = connectionService;

    const securityChannel = chan("security");

    securityChannel.reply("logout", this.logout, this);

    securityChannel.reply("showChangePasswordModal", this.showChangePasswordModal, this);

    securityChannel.reply("forcePasswordReset", this.forcePasswordReset, this);

    $(window).on("schemeLoginEvent.securityController", (event, url) => this.handleSchemeLoginEvent(event, url));
    $(window).on("focus.securityController", () => this.ensureUserSynced());
  }

  get route() {
    return "route:login route:organizationSplashScreen";
  }

  logout(userInitiated) {
    console.log("Logging out user, user initiated: %s", userInitiated);

    chan("display").request("showBlockingLoader");

    const user = this.securityService.getUser();

    if (user.hasProfile()) {
      replaceFragment("/login");
    }

    if (userInitiated) {
      chan("history").request("clear");
    }

    if (isProxyHostname(window.location.href) && process.env.PATRON_UI_RELOAD_WINDOW_ON_PROXY_LOGOUT === "true") {
      console.log("Assuming proxy, reloading on logout");

      return this.securityService.logout().then(() => {
        window.location.reload();
      });
    } else {
      console.log("Normal logout");

      //TODO: eventually get rid of this channel
      chan("controllerView").stopReplying();

      return this.securityService
        .logout()
        .then(() => this.securityService.initializeUser())
        .then(() => {
          refreshRoute();
        })
        .catch(error => this.showErrorPage(error))
        .finally(() => {
          chan("display").request("hideBlockingLoader");
        });
    }
  }

  ensureUserSynced() {
    console.log("Checking if persisted user matches user in memory...");

    return this.securityService
      .fetchUserFromLocalStorage()
      .then(localStorageUser => {
        const user = this.securityService.getUser();

        if (!L.isNil(localStorageUser) && !L.isNil(user)) {
          const userJson = user.toJSON();
          const localStorageUserJson = localStorageUser.toJSON();

          if (!L.isEqual(userJson, localStorageUserJson)) {
            console.log(
              `User in memory: %O is not in sync with user in local storage: %O
                        reinitializing user and refreshing the screen...`,
              userJson,
              localStorageUserJson,
            );

            const currentActiveOrganizationId = user.getActiveOrganizationId();

            if (localStorageUser.hasOrganization(currentActiveOrganizationId)) {
              console.log("Keeping active org: %O", currentActiveOrganizationId);
              //attempt to maintain the active org
              localStorageUser.setActiveOrganizationId(currentActiveOrganizationId);
            } else if (getDefaultOrganizationForUser(localStorageUser)) {
              const defaultOrganization = getDefaultOrganizationForUser(localStorageUser);
              console.log("Using default org: %O", defaultOrganization);
              localStorageUser.setActiveOrganizationId(defaultOrganization.organizationId);
            } else {
              return this.securityService.setUser(user).then(() => {
                chan("display").request("refreshScreen");
              });
            }

            return this.securityService.setUser(localStorageUser).then(() => {
              chan("display").request("refreshScreen");
            });
          }
        }
      })
      .catch(error => this.showErrorPage(error));
  }

  replyRoute(...args) {
    const user = this.securityService.getUser();

    if (user.isAuthenticated() && this.connectionService.isOnline()) {
      console.log("Replying to login route request, user is authenticated, showing the home page...");

      // change the url without firing a route changed event
      replaceFragment("/home");
      this.googleAnalyticsService.sendPageView("home");

      // create a new main view and new home page sub view
      let promise = chan("display").request("showFreshHomepage");

      // [Tyler] 11/12/2024 -- we are going to hide the sign in modal for now
      // if (!user.hasProfile() && !args.includes("menu")) {
      //   console.log("User is an organization guest, showing sign in modal...");

      //   promise = promise.then(() => this.showSignInModal());
      // }

      return promise;
    } else {
      const verification = this.securityService.authScopeInfo?.verification;
      const isProxyRedirect =
        verification && verification?.proxyPrefix && verification.proxyPrefix.startsWith(redirectPrefix);

      if (isProxyRedirect) {
        console.log("Replying to login route request, redirecting unauthenticated user to proxy...");
        chan("display").request("showBlockingLoader", 0);

        this.handleEzProzyRedirect(verification.proxyPrefix);
        return Promise.resolve();
      } else {
        console.log("Replying to login route request, user NOT authenticated, showing the login screen...");
        return this.showAuthenticationScreenBodyView(new LoginPageView());
      }
    }
  }

  handleEzProzyRedirect(proxyPrefix) {
    const lastFragment = chan("history").request("lastFragment");

    const url = new URL(`${window.location.origin}/${lastFragment ? lastFragment : "home"}`); // send to BB resource or go home
    url.searchParams.set("authScope", this.securityService.authScope);

    const alert = new EzProxyRedirectAlert({
      proxyPrefix: proxyPrefix.slice(redirectPrefix.length), // remove "redirect::"
      qurl: `${url.origin}${url.pathname}`,
      qurlParams: Object.fromEntries(url.searchParams),
      withReferer: false,
    });

    alert.redirectToEzProxy();
  }

  showSignInModal() {
    return chan("display").request("showModal", new SignInModalView());
  }

  forcePasswordReset(forcePasswordResetException) {
    const { user, password } = forcePasswordResetException;

    let changePasswordModal = new ChangePasswordModalView({
      username: user.getUsername(),
      token: user.getToken(),
      oldPassword: password,
    });

    chan("display").request("showModal", changePasswordModal, {
      backdrop: "static",
    });

    return new Promise((resolve, reject) => {
      changePasswordModal.once("success", () => {
        chan("display").request("closeModal").then(resolve);
      });

      changePasswordModal.once("failure", reject);
    });
  }

  handleSchemeLoginEvent(event, url) {
    const appScheme = parseAppSchemeUrl(url);

    if (appScheme !== null) {
      const token = appScheme.params[0];

      console.log("Scheme login token: %O, current user token: %O", token, this.securityService.getUser().getToken());

      this.securityService
        .loginWithToken(token)
        .then(() => {
          // bit of a hack
          const user = this.securityService.getUser();

          if (user.hasProfile()) {
            const user = this.securityService.getUser();
            user.setActiveOrganizationId(L.last(user.getOrganizationIds()));

            console.log("New user organization ids: %O", user.getOrganizationIds());

            return this.securityService.setUser(user);
          }
        })
        .then(() => {
          if (appScheme.route === "manageOrganizations") {
            replaceFragment("/manage-organizations");
          } else {
            const redirectFragment = parseRedirectFragmentFromUrl(url);

            console.log("app scheme url: %O, parsed redirectFragment: %O", url, redirectFragment);

            replaceFragment(!L.isNil(redirectFragment) ? redirectFragment : "/home");
          }

          chan("display").request("refreshScreen");
        })
        .catch(e => {
          console.log("An error occurred during scheme login: %O", e);
        });
    }

    return false;
  }

  showChangePasswordModal() {
    const changePasswordModal = new ChangePasswordModalView();

    changePasswordModal.once(
      "success",
      () => {
        alert("Password Changed", "Your password has been changed!", "success").show();
      },
      this,
    );

    changePasswordModal.once("failure", errorAlert);

    chan("display").request("showModal", changePasswordModal);
  }

  close() {
    $(window).off("schemeLoginEvent.securityController");
    $(window).off("focus.securityController");
  }
}

export default SecurityController;
