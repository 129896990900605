var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"platformTitle") || (depth0 != null ? lookupProperty(depth0,"platformTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"platformTitle","hash":{},"data":data,"loc":{"start":{"line":19,"column":63},"end":{"line":19,"column":80}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return "BiblioBoard Library";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <a\n                href=\"mailto:"
    + alias4(((helper = (helper = lookupProperty(helpers,"orgInfoEmail") || (depth0 != null ? lookupProperty(depth0,"orgInfoEmail") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"orgInfoEmail","hash":{},"data":data,"loc":{"start":{"line":24,"column":29},"end":{"line":24,"column":45}}}) : helper)))
    + "?bcc=support@biblioboard.com\"\n                target=\"_blank\"\n              >"
    + alias4(((helper = (helper = lookupProperty(helpers,"orgInfoEmail") || (depth0 != null ? lookupProperty(depth0,"orgInfoEmail") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"orgInfoEmail","hash":{},"data":data,"loc":{"start":{"line":26,"column":15},"end":{"line":26,"column":31}}}) : helper)))
    + "</a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "              <a\n                href=\"mailto:support@biblioboard.com\"\n                target=\"_blank\"\n              >support@biblioboard.com</a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"usingPlatform") : stack1), depth0))
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"usingBiblioboard") : stack1), depth0))
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"clr-gray m-b-5\"><a href=\"mailto:"
    + alias1(((helper = (helper = lookupProperty(helpers,"orgSupportEmail") || (depth0 != null ? lookupProperty(depth0,"orgSupportEmail") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"orgSupportEmail","hash":{},"data":data,"loc":{"start":{"line":50,"column":56},"end":{"line":50,"column":75}}}) : helper)))
    + "?bcc=support@biblioboard.com\" target=\"_blank\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"support") : stack1), depth0))
    + "</a></div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"clr-gray m-b-5\"><a href=\"mailto:support@biblioboard.com\" target=\"_blank\" rel=\"noreferrer noopener\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"support") : stack1), depth0))
    + "</a></div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <a href=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"externalAboutPageUrl") || (depth0 != null ? lookupProperty(depth0,"externalAboutPageUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"externalAboutPageUrl","hash":{},"data":data,"loc":{"start":{"line":62,"column":23},"end":{"line":62,"column":47}}}) : helper)))
    + "\" target=\"_blank\" rel=\"noreferrer noopener\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"aboutUs") : stack1), depth0))
    + "</a>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <a href=\"/about\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"aboutUs") : stack1), depth0))
    + "</a>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"clr-gray m-b-5\"><a\n                href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"marketingUrl") || (depth0 != null ? lookupProperty(depth0,"marketingUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"marketingUrl","hash":{},"data":data,"loc":{"start":{"line":69,"column":22},"end":{"line":69,"column":38}}}) : helper)))
    + "\"\n                target=\"_blank\"\n                rel=\"noreferrer noopener\"\n              >"
    + alias4(((helper = (helper = lookupProperty(helpers,"marketingUrlName") || (depth0 != null ? lookupProperty(depth0,"marketingUrlName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"marketingUrlName","hash":{},"data":data,"loc":{"start":{"line":72,"column":15},"end":{"line":72,"column":35}}}) : helper)))
    + "</a></div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"externalUrls") : depth0),{"name":"each","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":12},"end":{"line":81,"column":21}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"clr-gray m-b-5\"><a\n                  href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\"\n                  target=\"_blank\"\n                  rel=\"noreferrer noopener\"\n                >"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</a></div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"clr-gray m-b-5\"><a href=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"pledgeUrl") || (depth0 != null ? lookupProperty(depth0,"pledgeUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"pledgeUrl","hash":{},"data":data,"loc":{"start":{"line":85,"column":49},"end":{"line":85,"column":62}}}) : helper)))
    + "\" target=\"_blank\" rel=\"noreferrer noopener\">"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"footer") : stack1)) != null ? lookupProperty(stack1,"contribute") : stack1), depth0))
    + "</a></div>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"clr-gray m-b-5\"><a href=\"https://createsharediscover.com\" target=\"_blank\" rel=\"noreferrer noopener\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"footer") : stack1)) != null ? lookupProperty(stack1,"libraryResources") : stack1), depth0))
    + "</a></div>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"clr-gray m-b-5\"><a href=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"blogUrl") || (depth0 != null ? lookupProperty(depth0,"blogUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"blogUrl","hash":{},"data":data,"loc":{"start":{"line":91,"column":49},"end":{"line":91,"column":60}}}) : helper)))
    + "\" target=\"_blank\" rel=\"noreferrer noopener\">"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"footer") : stack1)) != null ? lookupProperty(stack1,"blog") : stack1), depth0))
    + "</a></div>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hideBiblioBlog") : depth0),{"name":"unless","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":12},"end":{"line":99,"column":23}}})) != null ? stack1 : "");
},"33":function(container,depth0,helpers,partials,data) {
    return "              <div class=\"clr-gray m-b-5\"><a\n                  href=\"https://createsharediscover.com/news/\"\n                  target=\"_blank\"\n                  rel=\"noreferrer noopener\"\n                >BiblioBlog</a></div>\n";
},"35":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"clr-gray m-b-5\"><a href=\"/eula\">EULA</a></div>\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"footer-submenu\">\n              <div class=\"h5 clr-primary fnt-bld\">INDIE AUTHOR PROJECT</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showSelfe") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":117,"column":14},"end":{"line":119,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"pressbooksUrl") : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":120,"column":14},"end":{"line":122,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"partnerSelfEUrl") : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":123,"column":14},"end":{"line":125,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showSelfe") : depth0),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":126,"column":14},"end":{"line":129,"column":21}}})) != null ? stack1 : "")
    + "            </div>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"clr-gray m-b-5\"><a href=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"generalSelfEUrl") || (depth0 != null ? lookupProperty(depth0,"generalSelfEUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"generalSelfEUrl","hash":{},"data":data,"loc":{"start":{"line":118,"column":53},"end":{"line":118,"column":72}}}) : helper)))
    + "\" target=\"_blank\" rel=\"noreferrer noopener\">"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"footer") : stack1)) != null ? lookupProperty(stack1,"forAuthors") : stack1), depth0))
    + "</a></div>\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"clr-gray m-b-5\"><a href=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"pressbooksUrl") || (depth0 != null ? lookupProperty(depth0,"pressbooksUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"pressbooksUrl","hash":{},"data":data,"loc":{"start":{"line":121,"column":53},"end":{"line":121,"column":70}}}) : helper)))
    + "\" target=\"_blank\" rel=\"noreferrer noopener\">"
    + alias1(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"footer") : stack1)) != null ? lookupProperty(stack1,"ebook") : stack1)) != null ? lookupProperty(stack1,"create") : stack1), depth0))
    + "</a></div>\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"clr-gray m-b-5\"><a href=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"partnerSelfEUrl") || (depth0 != null ? lookupProperty(depth0,"partnerSelfEUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"partnerSelfEUrl","hash":{},"data":data,"loc":{"start":{"line":124,"column":53},"end":{"line":124,"column":72}}}) : helper)))
    + "\" target=\"_blank\" rel=\"noreferrer noopener\">"
    + alias1(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"footer") : stack1)) != null ? lookupProperty(stack1,"ebook") : stack1)) != null ? lookupProperty(stack1,"share") : stack1), depth0))
    + "</a></div>\n";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"clr-gray m-b-5\"><a href=\"https://indieauthorproject.com/author-tools/\" target=\"_blank\" rel=\"noreferrer noopener\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"footer") : stack1)) != null ? lookupProperty(stack1,"forLibraries") : stack1), depth0))
    + "</a></div>\n                <div class=\"clr-gray m-b-5\"><a href=\"https://indieauthorproject.com/iap-select/\" target=\"_blank\" rel=\"noreferrer noopener\">IAP Select Collections</a></div>\n";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"footer-submenu\">\n            <div class=\"h5 clr-primary fnt-bld text-uppercase\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"footer") : stack1)) != null ? lookupProperty(stack1,"downloadApp") : stack1), depth0))
    + "</div>\n            <div id=\"app-store-logos\">\n\n              <!--apple link-->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAppleAppLogo") : depth0),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":139,"column":14},"end":{"line":145,"column":21}}})) != null ? stack1 : "")
    + "\n              <!--google play link-->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showGoogleAppLogo") : depth0),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":14},"end":{"line":154,"column":21}}})) != null ? stack1 : "")
    + "\n              <!--kindle link-->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showKindleAppLogo") : depth0),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":157,"column":14},"end":{"line":163,"column":21}}})) != null ? stack1 : "")
    + "\n            </div>\n          </div>\n";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a href=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"appleAppDownloadUrl") || (depth0 != null ? lookupProperty(depth0,"appleAppDownloadUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"appleAppDownloadUrl","hash":{},"data":data,"loc":{"start":{"line":140,"column":25},"end":{"line":140,"column":48}}}) : helper)))
    + "\" target=\"_blank\">\n                  <div class=\"btn-link d-iblock\" id=\"ftrbtn-apple\">\n                    <img src=\"" + require("/opt/atlassian/pipelines/agent/build/node_modules/handlebars-template-loader/file-loader.js?url=..%2Fimages%2Ficon-apple-store.png!../images/icon-apple-store.png") + "\" alt=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"appStore") : stack1), depth0))
    + "\">\n                  </div>\n                </a>\n";
},"49":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a href="
    + alias1(((helper = (helper = lookupProperty(helpers,"googleAppDownloadUrl") || (depth0 != null ? lookupProperty(depth0,"googleAppDownloadUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"googleAppDownloadUrl","hash":{},"data":data,"loc":{"start":{"line":149,"column":24},"end":{"line":149,"column":48}}}) : helper)))
    + " target=\"_blank\">\n                  <div class=\"btn-link d-iblock\" id=\"ftrbtn-googleplay\">\n                    <img src=\"" + require("/opt/atlassian/pipelines/agent/build/node_modules/handlebars-template-loader/file-loader.js?url=..%2Fimages%2Ficon-play.png!../images/icon-play.png") + "\" alt=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"playStore") : stack1), depth0))
    + "\">\n                  </div>\n                </a>\n";
},"51":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a href="
    + alias1(((helper = (helper = lookupProperty(helpers,"kindleAppDownloadUrl") || (depth0 != null ? lookupProperty(depth0,"kindleAppDownloadUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"kindleAppDownloadUrl","hash":{},"data":data,"loc":{"start":{"line":158,"column":24},"end":{"line":158,"column":48}}}) : helper)))
    + " target=\"_blank\">\n                  <div class=\"btn-link d-iblock\" id=\"ftrbtn-kindle\">\n                    <img src=\"" + require("/opt/atlassian/pipelines/agent/build/node_modules/handlebars-template-loader/file-loader.js?url=..%2Fimages%2Ficon-amazon.png!../images/icon-amazon.png") + "\" alt=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"amazon") : stack1), depth0))
    + "\">\n                  </div>\n                </a>\n";
},"53":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a aria-label=\"Instagram\" href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"instagramUrl") || (depth0 != null ? lookupProperty(depth0,"instagramUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"instagramUrl","hash":{},"data":data,"loc":{"start":{"line":177,"column":44},"end":{"line":177,"column":60}}}) : helper)))
    + "\" target=\"_blank\">\n              <div class=\"clr-white d-iblock fnt-20\"><i\n                  class=\"fa-brands fa-instagram\"\n                ></i></div>\n            </a>\n";
},"55":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a aria-label=\"Facebook\" href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"facebookUrl") || (depth0 != null ? lookupProperty(depth0,"facebookUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"facebookUrl","hash":{},"data":data,"loc":{"start":{"line":184,"column":43},"end":{"line":184,"column":58}}}) : helper)))
    + "\" target=\"_blank\">\n              <div class=\"clr-white d-iblock fnt-20 m-l-10\"><i\n                  class=\"fa-brands fa-facebook\"\n                ></i></div>\n            </a>\n";
},"57":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a aria-label=\"Twitter\" href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"twitterUrl") || (depth0 != null ? lookupProperty(depth0,"twitterUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"twitterUrl","hash":{},"data":data,"loc":{"start":{"line":191,"column":42},"end":{"line":191,"column":56}}}) : helper)))
    + "\" target=\"_blank\">\n              <div class=\"clr-white d-iblock fnt-20 m-l-10\"><i\n                  class=\"fa-brands fa-x-twitter\"\n                ></i></div>\n            </a>\n";
},"59":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a aria-label=\"LinkedIn\" href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"linkedInUrl") || (depth0 != null ? lookupProperty(depth0,"linkedInUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"linkedInUrl","hash":{},"data":data,"loc":{"start":{"line":198,"column":43},"end":{"line":198,"column":58}}}) : helper)))
    + "\" target=\"_blank\">\n              <div class=\"clr-white d-iblock fnt-20 m-l-10\"><i\n                  class=\"fa-brands fa-linkedin\"\n                ></i></div>\n            </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"bg-white p-t-40\">\n  <div id=\"footer\" role=\"contentinfo\">\n\n    <div id=\"footer-main\">\n      <!-- Logo and Support Area -->\n      <div id=\"footer-left\">\n        <div class=\"m-b-10\">\n          <div>\n            <img\n              src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"logo") || (depth0 != null ? lookupProperty(depth0,"logo") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"logo","hash":{},"data":data,"loc":{"start":{"line":10,"column":19},"end":{"line":10,"column":27}}}) : helper)))
    + "\"\n              class=\"img-responsive footer-logo\"\n              alt=\"BiblioBoard\"\n            />\n          </div>\n        </div>\n\n        <div class=\"m-lr-0 m-b-10\">\n          <div class=\"fnt-14 clr-gray\">\n            "
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"footer") : stack1)) != null ? lookupProperty(stack1,"forAssistance") : stack1), depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"platformTitle") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":19,"column":42},"end":{"line":19,"column":114}}})) != null ? stack1 : "")
    + ", email\n          </div>\n          <div class=\"fnt-18 fnt-bld clr-gray m-b-20\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"orgInfoEmail") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":32,"column":19}}})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n\n      <!-- Footer Menu -->\n      <div id=\"footer-menu\" class=\"flex-sb\">\n        <div class=\"footer-submenu\">\n          <!-- This could be a heading, but it has to fall in proper order with all other pages -->\n          <div class=\"h5 clr-primary fnt-bld text-uppercase\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"platformTitle") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":42,"column":12},"end":{"line":46,"column":19}}})) != null ? stack1 : "")
    + "          </div>\n          <div class=\"clr-gray m-b-5\"><a href=\"https://vimeo.com/195458066\" target=\"_blank\" rel=\"noreferrer noopener\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"gettingStarted") : stack1), depth0))
    + "</a></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"orgSupportEmail") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":49,"column":10},"end":{"line":53,"column":17}}})) != null ? stack1 : "")
    + "          <div class=\"clr-gray m-b-5\"><a href=\"/diagnostics\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"diagnostics") : stack1), depth0))
    + "</a></div>\n        </div>\n\n        <div class=\"footer-submenu\">\n          <!-- This could be a heading, but it has to fall in proper order with all other pages -->\n          <div class=\"h5 clr-primary fnt-bld text-uppercase\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"moreInformation") : stack1), depth0))
    + "</div>\n          <div class=\"clr-gray m-b-5\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"externalAboutPageUrl") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":61,"column":12},"end":{"line":65,"column":19}}})) != null ? stack1 : "")
    + "          </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"marketingUrl") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":10},"end":{"line":73,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"externalUrls") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":10},"end":{"line":82,"column":17}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"pledgeUrl") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":84,"column":10},"end":{"line":86,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"pledgeUrl") : depth0),{"name":"unless","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":87,"column":10},"end":{"line":89,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"blogUrl") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.program(32, data, 0),"data":data,"loc":{"start":{"line":90,"column":10},"end":{"line":100,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n\n        <div class=\"footer-submenu\">\n          <!-- This could be a heading, but it has to fall in proper order with all other pages -->\n          <div class=\"h5 clr-primary fnt-bld text-uppercase\">"
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"footer") : stack1)) != null ? lookupProperty(stack1,"policy") : stack1)) != null ? lookupProperty(stack1,"plural") : stack1), depth0))
    + "</div>\n          <div class=\"clr-gray m-b-5\"><a href=\"/privacy\" target=\"_blank\" rel=\"noreferrer noopener\">"
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"footer") : stack1)) != null ? lookupProperty(stack1,"policy") : stack1)) != null ? lookupProperty(stack1,"privacy") : stack1), depth0))
    + "</a></div>\n          <div class=\"clr-gray m-b-5\"><button id=\"bb-footer-cookie-settings-link\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"footer") : stack1)) != null ? lookupProperty(stack1,"cookieSettings") : stack1), depth0))
    + "</button></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showLegalNotices") : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":10},"end":{"line":110,"column":17}}})) != null ? stack1 : "")
    + "          <div class=\"clr-gray m-b-5\"><a href=\"https://biblioboard.com/accessibility/\" target=\"_blank\" rel=\"noreferrer noopener\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"footer") : stack1)) != null ? lookupProperty(stack1,"accessibility") : stack1), depth0))
    + "</a></div>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAuthorsMenu") : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":114,"column":8},"end":{"line":131,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAppLogos") : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":133,"column":8},"end":{"line":167,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n\n    <div id=\"footer-social\" class=\"m-t-30\">\n      <div class=\"flex-sb-c w-90p\">\n        <div class=\"h6\">© "
    + alias4(((helper = (helper = lookupProperty(helpers,"currentYear") || (depth0 != null ? lookupProperty(depth0,"currentYear") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currentYear","hash":{},"data":data,"loc":{"start":{"line":173,"column":26},"end":{"line":173,"column":41}}}) : helper)))
    + " BiblioLabs. "
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"footer") : stack1)) != null ? lookupProperty(stack1,"allRightsReserved") : stack1), depth0))
    + "</div>\n        <div class=\"h6 p-lr-5\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"attributionStmt") || (depth0 != null ? lookupProperty(depth0,"attributionStmt") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"attributionStmt","hash":{},"data":data,"loc":{"start":{"line":174,"column":31},"end":{"line":174,"column":50}}}) : helper)))
    + "</div>\n        <div class=\"m-tb-10\" role=\"navigation\" aria-label=\"footer menu\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"instagramUrl") : depth0),{"name":"if","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":176,"column":10},"end":{"line":182,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"facebookUrl") : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":183,"column":10},"end":{"line":189,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"twitterUrl") : depth0),{"name":"if","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":190,"column":10},"end":{"line":196,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"linkedInUrl") : depth0),{"name":"if","hash":{},"fn":container.program(59, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":197,"column":10},"end":{"line":203,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n      </div>\n    </div>\n\n  </div>\n</div>";
},"useData":true});