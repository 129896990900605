import querystring from "querystring";
import inject from "scripts/ioc/inject";
import { alert } from "scripts/alerts/alerts";

export const createEzProxyUrl = (proxyPrefix, qurl, qurlParams, withReferer = true) => {
  let params = qurlParams;

  if (withReferer) {
    params = {
      referer: encodeURIComponent(proxyPrefix),
      ...params,
    };
  }

  const url = `${qurl}?${querystring.stringify(params)}`;

  return `${proxyPrefix}${encodeURIComponent(url)}`;
};

export const redirectPrefix = "redirect::";

class EzProxyRedirectAlert {
  constructor(options, securityService = inject("securityService")) {
    this.state = options;
    this.securityService = securityService;
  }

  show() {
    const { organizationName } = this.state;

    alert({
      html: true,
      title: `Taking you to<br/><span class="clr-primary">${organizationName}</span><br/>to sign in`,
      text: `Once you sign in, you'll be redirected back to BiblioBoard&reg;.`,
      type: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: "cancel",
      confirmButtonText: "Got it!",
    })
      .show()
      .then(() => {
        this.redirectToEzProxy();
      });
  }

  redirectToEzProxy() {
    const { proxyPrefix, qurl, qurlParams, target, withReferer } = this.state;

    const redirectUrl = createEzProxyUrl(proxyPrefix, qurl, qurlParams, withReferer);

    console.log("Redirecting to %O, target: %O", redirectUrl, target);

    if (target === "_blank") {
      window.open(redirectUrl, "_system");
    } else {
      window.location.href = redirectUrl;
    }
  }
}

export default EzProxyRedirectAlert;
