import L from "lodash/fp";
import { purep, reject, sequencep } from "bluebird-promisell";
import Promise from "bluebird";
import docCookies from "doc-cookies";
import UnauthorizedSyncError from "scripts/exceptions/unauthorizedSyncError";
import UserForcePasswordResetException from "scripts/exceptions/userForcePasswordResetException";
import UserModel from "scripts/models/userModel";
import BaseService from "scripts/services/baseService";
import inject from "scripts/ioc/inject";

import { andThenP, catchIfP, ifElse, isInstance, nullP, pass, unless, when } from "scripts/utils/generalHelpers";

import { getUrlParameter, replaceCurrentUrl, removeParameterFromCurrentUrl } from "scripts/utils/urlUtil";

import { hasOrganization, getUserOrganizationForAlias, setActiveOrganizationId } from "scripts/utils/userHelpers";

import {
  USE_LOCATION_FOR_ORG_AUTH,
  fetchGeoCoords,
  fetchOrganizationTokenFromLocalStorage,
  fetchOrganizationUser,
  fetchUserForToken,
  fetchUserFromLocalStorage,
  fetchUserJsonFromLocalStorage,
  fetchUserWithUsernameAndPassword,
  getAuthScopeFromSubdomain,
  getCoordsFromUrl,
  getDefaultOrganizationForUser,
  getRefererRouteParameter,
  getTokenFromUrl,
  isLegacyUser,
  isMigratedUser,
  migrateUserJson,
  removeCoordsFromUrl,
  removeRefererRouteParameter,
  removeUserFromLocalStorage,
  getAuthDomainScope,
} from "scripts/utils/securityHelpers";

import {
  associateUserWithOrganization,
  disassociateUserFromOrganization,
  invalidateTokenForOrganizationId,
  invalidateTokenForUserId,
} from "scripts/utils/fetchSecurity";

import { fetchOrganizationProfileFromApi } from "scripts/utils/fetchApi";

const chan = Backbone.Radio.channel;

const fetchGeoIfUseLocationForOrgAuth = () =>
  USE_LOCATION_FOR_ORG_AUTH ? fetchGeoCoords(navigator.geolocation).catch(nullP) : nullP();

const fetchActiveOrganizationIdFromSessionStorage = sessionStorageService =>
  sessionStorageService.getAttributes().then(attrs => attrs.activeOrganizationId);

const getAndRemoveUrlParam = param =>
  L.compose(
    pass(() => removeParameterFromCurrentUrl(param)),
    () => getUrlParameter(param, window.location.href),
  );

const getActiveOrganizationIdFromUrl = user =>
  L.compose(
    when(
      L.isNil,
      L.compose(L.prop("organizationId"), getUserOrganizationForAlias(user), getAndRemoveUrlParam("library")),
    ),
    when(
      L.isNil,
      L.compose(L.prop("organizationId"), getUserOrganizationForAlias(user), getAndRemoveUrlParam("organization")),
    ),
    when(L.isNil, getAndRemoveUrlParam("libraryId")),
    getAndRemoveUrlParam("organizationId"),
  );

const produceDefaultOrganizationId = user =>
  L.compose(L.prop("organizationId"), () => getDefaultOrganizationForUser(user));

// fetchInitialActiveOrganizationId  :: (SessionStorageService, User) -> Promise String
const fetchInitialActiveOrganizationId = (sessionStorageService, user) =>
  L.compose(
    andThenP(unless(hasOrganization(user), produceDefaultOrganizationId(user))),
    ifElse(hasOrganization(user), purep, () => fetchActiveOrganizationIdFromSessionStorage(sessionStorageService)),
    getActiveOrganizationIdFromUrl(user),
  )();

// setUserInitialActiveOrganization :: SessionStorageService -> User -> Promise User
const setUserInitialActiveOrganization = sessionStorageService => user =>
  fetchInitialActiveOrganizationId(sessionStorageService, user).then(organizationId =>
    setActiveOrganizationId(user, organizationId),
  );

// hasOrganizationPreferencesInLocalStorage :: (User, UserOrganizationPreferencesService) -> Boolean
const hasOrganizationPreferencesInLocalStorage = (user, userOrganizationPreferencesService) => {
  return () => {
    userOrganizationPreferencesService.getPreferences({ id: user.getUserId() }).then(localStoragePreferences => {
      return !L.isNil(localStoragePreferences);
    });
    // const id = isBlank(user.getUserId()) ? null : user.getUserId();
    // userOrganizationPreferencesService.getPreferences({ id }).then(localStoragePreferences => {
    //   return !L.isNil(localStoragePreferences);
    // });
  };
};

// getActiveOrganizationIdFromLocalStorage :: (User, UserOrganizationPreferencesService) -> String
const getActiveOrganizationIdFromLocalStorage = (user, userOrganizationPreferencesService) => {
  return () => {
    userOrganizationPreferencesService
      .getPreferences({ id: user.getUserId() })
      .then(localStoragePreferences => L.head(localStoragePreferences));
    // const id = isBlank(user.getUserId()) ? null : user.getUserId();
    // userOrganizationPreferencesService
    //   .getPreferences({ id })
    //   .then(localStoragePreferences => L.head(localStoragePreferences));
  };
};

// getActiveOrganizationIdOnLogin :: (User, UserOrganizationPreferencesService) -> String
const getActiveOrganizationIdOnLogin = (user, userOrganizationPreferencesService) =>
  L.compose(
    unless(
      hasOrganization(user),
      ifElse(
        hasOrganizationPreferencesInLocalStorage(user, userOrganizationPreferencesService),
        getActiveOrganizationIdFromLocalStorage(user, userOrganizationPreferencesService),
        produceDefaultOrganizationId(user),
      ),
    ),
    getActiveOrganizationIdFromUrl(user),
  )();

// setUserActiveOrganizationOnLogin :: UserOrganizationPreferencesService -> User -> User
const setUserActiveOrganizationOnLogin = userOrganizationPreferencesService => user =>
  L.compose(setActiveOrganizationId(user), () =>
    getActiveOrganizationIdOnLogin(user, userOrganizationPreferencesService),
  )();

class SecurityService extends BaseService {
  constructor(
    connectionService = inject("connectionService"),
    sessionStorageService = inject("sessionStorageService"),
    userOrganizationPreferencesService = inject("userOrganizationPreferencesService"),
  ) {
    super();

    this.connectionService = connectionService;
    this.sessionStorageService = sessionStorageService;
    this.userOrganizationPreferencesService = userOrganizationPreferencesService;

    this.authScope = null;
    this.authDomainScope = null; // for future use as a 'site' or higher level scope
    this.authScopeInfo = {};
    this.referer = null;
    this.requestedUrl = null;
    this.patronId = null;

    chan("security").reply(
      "user",
      () => {
        // console.warn(`Obtaining users via the security channel is deprecated,
        // use the security service directly`);
        return this.getUser();
      },
      this,
    );
  }

  initialize() {
    console.log("Initializing security...");

    this.initializeAuthScope();
    this.initializeReferer();
    this.initializeRequestedUrl();
    this.initializePatronId();
    return this.initializeUser();
  }

  initializeUser() {
    console.log("Initializing user...");

    return this.fetchInitialUser()
      .then(setUserInitialActiveOrganization(this.sessionStorageService))
      .then(user => this.setUser(user))
      .catch(e => {
        console.log("Error fetching initial user: %O, using new empty user", e);
        return this.setUser(new UserModel());
      });
  }

  fetchInitialUser() {
    console.log("Fetching initial user...");

    const url = window.location.href;
    const { authScope } = this;

    if (!this.connectionService.isOnline()) {
      console.log("Offline, fetching user from local storage...");

      return fetchUserJsonFromLocalStorage(authScope)
        .then(
          when(
            isLegacyUser,
            L.pipe(
              pass(userJson => console.log("Offline, migrating legacy user: %O", userJson)),
              migrateUserJson,
              pass(migratedUser => console.log("Migrated user: %O", migratedUser)),
            ),
          ),
        )
        .then(userJson => new UserModel(userJson));
    }

    const token = getTokenFromUrl(url);
    if (token) {
      console.log("Found token url parameter, fetching user for token: %O", token);
      removeParameterFromCurrentUrl("token");
      return fetchUserForToken({ authScope, token });
    }

    const coords = getCoordsFromUrl(url);
    if (coords) {
      console.log("Found geo coords in url, fetching user for coords: %O", coords);
      replaceCurrentUrl(removeCoordsFromUrl(url));
      return fetchOrganizationUser({ authScope, coords });
    }

    return fetchUserJsonFromLocalStorage(authScope)
      .then(userJson => {
        if (isLegacyUser(userJson) || isMigratedUser(userJson)) {
          console.log("Legacy or migrated user, fetching new user for existing token...");
          return fetchUserForToken({ authScope, token: userJson.token });
        } else {
          return L.isNil(userJson) ? new UserModel() : new UserModel(userJson);
        }
      })
      .then(user => {
        if (user.hasProfile()) {
          console.log("User has a profile, using it...");
          return user;
        } else {
          console.log("User in local storage does not have a profile, fetching organization user...");
          return this.fetchOrganizationUser();
        }
      })
      .then(pass(user => this.getAuthScopeInfoFromUser(user)))
      .catch(catchIfP(isInstance(UnauthorizedSyncError), error => this.fetchAuthScopeInfoFromError(error)));
  }

  getAuthScopeInfoFromUser(user) {
    if (!L.isNil(this.authScope)) {
      const organization = L.head(user.getOrganizations());

      this.authScopeInfo = {
        organizationId: organization.organizationId,
        organizationName: organization.name,
        brandingLogoUrl: organization.brandingLogoUrl,
      };

      console.log("Org auth succeeded for auth scoped org auth request, auth scope info: %O", this.authScopeInfo);
    }
  }

  fetchAuthScopeInfoFromError(unauthorizedSyncError) {
    if (!L.isNil(this.authScope)) {
      const scope = L.path(["xhr", "responseJSON", "scope"], unauthorizedSyncError);

      if (!L.isNil(scope) && !L.isEmpty(scope)) {
        return fetchOrganizationProfileFromApi({
          organizationId: scope.organizationId,
        })
          .then(({ brandingLogoUrl }) => ({
            brandingLogoUrl,
            ...scope,
          }))
          .then(authScopeInfo => {
            this.authScopeInfo = authScopeInfo;
          })
          .then(() => reject(unauthorizedSyncError));
      }
    }

    return reject(unauthorizedSyncError);
  }

  fetchUserFromLocalStorage() {
    return fetchUserFromLocalStorage(this.authScope);
    // .catch(e => {
    //     console.log('Error fetching user from local storage: %O, returning new empty user...', e);
    //     return new UserModel();
    // });
  }

  initializeAuthScope() {
    this.authDomainScope = getAuthDomainScope(window.location.href);
    console.log("Scoping for domain: %O", this.authDomainScope);

    const authScopeUrlParam = getUrlParameter("authScope", window.location.href);
    const authScopeSubdomain = this.getAuthScopeFromSubdomain();

    if (authScopeSubdomain && authScopeUrlParam) {
      console.log("Auth scope is in subdomain, removing authScope url parameter: %O...", authScopeUrlParam);
      removeParameterFromCurrentUrl("authScope");
    }

    if (authScopeSubdomain) {
      console.log("Using auth scope from subdomain: %O", authScopeSubdomain);
      this.authScope = authScopeSubdomain;
    } else if (authScopeUrlParam) {
      console.log("Using auth scope from url parameter: %O", authScopeUrlParam);
      this.authScope = authScopeUrlParam;
    }
  }

  initializeReferer() {
    const refererUrlParam = getUrlParameter("referer", window.location.href);

    if (refererUrlParam) {
      console.log("Found referer url parameter: %O, removing it...", refererUrlParam);
      removeParameterFromCurrentUrl("referer");
    }

    const refererRouteParam = getRefererRouteParameter(window.location.href);

    if (refererRouteParam) {
      console.log("Found referer in route parameter: %O, removing it...", refererRouteParam);
      replaceCurrentUrl(removeRefererRouteParameter(window.location.href));
    }

    if (refererUrlParam) {
      console.log("Using referer url param: %O", refererUrlParam);
      this.referer = refererUrlParam;
    } else if (refererRouteParam) {
      console.log("Using referer route param: %O", refererRouteParam);
      this.referer = refererRouteParam;
    } else {
      console.log("Using document.referrer: %O", document.referrer);
      this.referer = document.referrer;
    }
  }

  initializeRequestedUrl() {
    const requestedUrlFromCookie = docCookies.getItem("url_before_rewrite");

    if (requestedUrlFromCookie) {
      console.info("Found requested url in url_before_rewrite cookie: %O", requestedUrlFromCookie);
      this.requestedUrl = requestedUrlFromCookie;
    } else {
      // app: is used by codova iOS as a local scheme
      this.requestedUrl = document.URL.replace("app:", "file:");
    }
  }

  initializePatronId() {
    const patronIdUrlParam = getUrlParameter("patronId", window.location.href);

    if (patronIdUrlParam) {
      this.patronId = patronIdUrlParam;
      removeParameterFromCurrentUrl("patronId");
    }
  }

  fetchOrganizationUserUsingGeoFetcher(geoFetcher) {
    const { authScope, requestedUrl, referer } = this;

    return Promise.all([geoFetcher(), fetchOrganizationTokenFromLocalStorage({ authScope })]).then(
      ([coords, token]) => {
        const { userAgent } = navigator;

        return fetchOrganizationUser({
          authScope,
          token,
          requestedUrl,
          referer,
          coords,
          userAgent,
        });
      },
    );
  }

  fetchOrganizationUser() {
    return this.fetchOrganizationUserUsingGeoFetcher(fetchGeoIfUseLocationForOrgAuth);
  }

  fetchOrganizationUserUsingGeo() {
    return this.fetchOrganizationUserUsingGeoFetcher(() => fetchGeoCoords(navigator.geolocation));
  }

  loginWithUsernameAndPassword({ username, password, captcha, audience }) {
    const { authScope, patronId } = this;

    return fetchUserWithUsernameAndPassword({
      authScope,
      username,
      password,
      patronId,
      captcha,
      audience,
      userAgent: navigator.userAgent,
    })
      .then(setUserActiveOrganizationOnLogin(this.userOrganizationPreferencesService))
      .then(user => {
        this.userOrganizationPreferencesService
          .getPreferences({
            id: user.getUserId(),
          })
          .then(preferences => {
            if (!L.isEqual(L.size(preferences), L.size(user.getOrganizations()))) {
              this.userOrganizationPreferencesService
                .save({
                  id: user.getUserId(),
                  organizationPreferences: L.map("organizationId", user.getOrganizations()),
                })
                .then(({ attributes }) => {
                  user.setActiveOrganizationId(L.head(attributes.organizationPreferences));
                });
            } else {
              user.setActiveOrganizationId(L.head(preferences));
            }
          });
        return user;
      })
      .then(user => {
        if (user.isForcePasswordReset()) {
          const userProfile = user.get("user");
          user.set("user", L.dissoc("forcePasswordReset", userProfile));

          return this.setUser(user).then(() => {
            throw new UserForcePasswordResetException(user, password);
          });
        } else {
          return this.setUser(user);
        }
      });
  }

  loginWithToken(token) {
    const { authScope } = this;

    return fetchUserForToken({ authScope, token })
      .then(setUserActiveOrganizationOnLogin)
      .then(user => this.setUser(user));
  }

  logout() {
    this.patronId = null;

    chan("security").trigger("logout", this.getUser());

    return this.invalidateToken()
      .then(() => this.sessionStorageService.clearAttributes())
      .then(() => this.setUser(new UserModel()))
      .catch(e => {
        console.log("Error invalidating token: %O", e);
        return this.setUser(new UserModel());
      });
  }

  invalidateToken() {
    const userId = this.user.getUserId();
    const token = this.user.getToken();

    if (userId) {
      return invalidateTokenForUserId(userId, token);
    } else {
      const activeOrganizationId = this.user.getActiveOrganizationId();

      if (activeOrganizationId) {
        return invalidateTokenForOrganizationId(this.user.getActiveOrganizationId(), token);
      } else {
        return purep(null);
      }
    }
  }

  getUser() {
    if (!L.isNil(this.user)) {
      return this.user.clone();
    } else {
      return null;
    }
  }

  setUser(user) {
    const previousUser = this.user;

    return removeUserFromLocalStorage()
      .then(() => {
        return this.userOrganizationPreferencesService.getPreferences({
          id: user.getUserId(),
        });
      })
      .then(preferences => {
        const activeOrganizationId = preferences ? L.head(preferences) : user.getActiveOrganizationId();
        if (activeOrganizationId) {
          console.log("Setting new active org for user: %O", activeOrganizationId);
          user.setActiveOrganizationId(activeOrganizationId);
          console.log("Setting active organization id in session storage: %O", activeOrganizationId);
          return this.sessionStorageService.setAttributes({
            activeOrganizationId: activeOrganizationId,
          });
        }
      })
      .then(() => {
        if (this.authScope) {
          user.set("authScope", this.authScope);
        }

        this.user = user;
        return this.user.save();
      })
      .then(() => (!previousUser ? chan("bookshelf").request("migrate") : nullP()))
      .then(() => {
        if (!previousUser || previousUser.getUserId() !== user.getUserId()) {
          chan("security").trigger("new:user", user);
        }

        const newActiveOrganizationId = user.getActiveOrganizationId();
        console.log("New active org id: ", newActiveOrganizationId);

        if (
          newActiveOrganizationId &&
          (!previousUser || previousUser.getActiveOrganizationId() !== newActiveOrganizationId)
        ) {
          console.log("Active organization changed, logging organization session");

          chan("tracking").trigger("authentication:complete", user);
          chan("display").request("setFavicon", this.user.getActiveOrganizationDynamicProperty("favicon"));
        }
      })
      .then(() => this.getUser());
  }

  getAuthScopeFromSubdomain() {
    const currentLocation = window.location.href;

    if (/^.*ebooks\.ohiolink\.edu/.test(currentLocation)) {
      return "ohiolinkedu";
    } else if (/^https?:\/\/openresearchlibrary.org/.test(currentLocation)) {
      return "openresearchlibrary";
    } else {
      return getAuthScopeFromSubdomain(currentLocation);
    }
  }

  associateUserWithOrganizations(organizationToken, organizationIds, patronId) {
    const user = this.getUser();

    if (user.hasOrganizations(organizationIds)) {
      console.log("user: %O is already associated with organizations: %O", user, organizationIds);
      return purep(user);
    } else {
      const currentActiveOrganizationId = user.getActiveOrganizationId();

      return sequencep(
        L.map(
          organizationId =>
            associateUserWithOrganization(
              user.getToken(),
              organizationToken,
              organizationId,
              user.getUserId(),
              patronId,
            ),
          organizationIds,
        ),
      )
        .then(() =>
          fetchUserForToken({
            authScope: this.authScope,
            token: user.getToken(),
          }),
        )
        .then(newUser => {
          // if (newUser.hasProfile()) {
          L.map(
            orgId =>
              this.userOrganizationPreferencesService.addOrg({
                id: newUser.getUserId(),
                newOrg: orgId,
                orgIds: L.map("organizationId", newUser.getOrganizations()),
              }),
            organizationIds,
          );
          // }
          newUser.setActiveOrganizationId(currentActiveOrganizationId);
          return this.setUser(newUser);
        });
    }
  }

  disassociateUserFromOrganizations(organizationToken, organizationIds) {
    const user = this.getUser();

    if (!user.hasOrganizations(organizationIds)) {
      return purep(user);
    } else {
      const currentActiveOrganizationId = user.getActiveOrganizationId();

      return sequencep(
        L.map(
          organizationId =>
            disassociateUserFromOrganization(user.getToken(), organizationToken, organizationId, user.getUserId()),
          organizationIds,
        ),
      )
        .then(() =>
          fetchUserForToken({
            authScope: this.authScope,
            token: user.getToken(),
          }),
        )
        .then(newUser => {
          newUser.setActiveOrganizationId(currentActiveOrganizationId);
          return this.setUser(newUser);
        });
    }
  }
}

export default SecurityService;
