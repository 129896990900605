var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "active";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"content-details-nav\">\n    <div class=\"container\">\n        <div class=\"row-same-height row-full-height\">\n            <nav>\n                <div class=\"navigation-container\">\n                    <div class=\"tab-less-navigation-header\">\n                        <div class=\"module-navbar-header-container\">\n                            <div class=\"module-count-container\">\n                                <div id=\"bb-content-details-page-nav-heading-details\" class=\"module-count\">\n                                    <!-- Put module count here -->\n                                </div>\n                            </div>\n                            <div class=\"collection-view-toggle module-collection-view-toggle\">\n                                <!--switch view type button-->\n                                <a id=\"bb-secondary-sticky-nav-presentation-type-toggle-list\"\n                                   aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"list") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                                   class=\"fade-on-hover hide cntntmast-view-type-button\"\n                                   href=\"/"
    + alias2((lookupProperty(helpers,"getMediaId")||(depth0 && lookupProperty(depth0,"getMediaId"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"id") : depth0),(depth0 != null ? lookupProperty(depth0,"internalCode") : depth0),{"name":"getMediaId","hash":{},"data":data,"loc":{"start":{"line":18,"column":42},"end":{"line":18,"column":72}}}))
    + "/list/"
    + alias2(((helper = (helper = lookupProperty(helpers,"contentType") || (depth0 != null ? lookupProperty(depth0,"contentType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"contentType","hash":{},"data":data,"loc":{"start":{"line":18,"column":78},"end":{"line":18,"column":93}}}) : helper)))
    + "\">\n                                    <div><i class=\"bbico bbico-list m-r-5\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"list") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</div>\n                                </a>\n\n                                <a id=\"bb-secondary-sticky-nav-presentation-type-toggle-masonry\"\n                                   aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"grid") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                                   class=\"fade-on-hover hide cntntmast-view-type-button\"\n                                   href=\"/"
    + alias2((lookupProperty(helpers,"getMediaId")||(depth0 && lookupProperty(depth0,"getMediaId"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"id") : depth0),(depth0 != null ? lookupProperty(depth0,"internalCode") : depth0),{"name":"getMediaId","hash":{},"data":data,"loc":{"start":{"line":25,"column":42},"end":{"line":25,"column":72}}}))
    + "/masonry/"
    + alias2(((helper = (helper = lookupProperty(helpers,"contentType") || (depth0 != null ? lookupProperty(depth0,"contentType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"contentType","hash":{},"data":data,"loc":{"start":{"line":25,"column":81},"end":{"line":25,"column":96}}}) : helper)))
    + "\">\n                                    <div><i class=\"fa fa-th m-r-5\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"grid") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</div>\n                                </a>\n                            </div>\n\n                            <div class=\"bb-media-page-menu-filter-region\">\n                                <div class=\"btn-group\">\n                                    <button \n                                        type=\"button\" \n                                        class=\"btn btn-rounded btn-rounded-brand-primary dropdown-toggle\" \n                                        data-toggle=\"dropdown\" \n                                        aria-haspopup=\"true\" \n                                        aria-expanded=\"false\"\n                                    >\n                                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"sortBy") : stack1), depth0))
    + " <span><i class=\"fa fa-angle-down\"></i></span>\n                                    </button>\n                                    <ul class=\"dropdown-menu\">\n                                        <li><button value=\"name asc\" class=\"sort-option btn-text "
    + ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"sortOption") : depth0),"name asc",{"name":"equal","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":97},"end":{"line":42,"column":145}}})) != null ? stack1 : "")
    + "\">\n                                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"name") : stack1)) != null ? lookupProperty(stack1,"asc") : stack1), depth0))
    + "  <span class=\"m-l-20\"><i class=\"bbico bbico-check\"></i></span>\n                                        </button></li>\n                                        <li><button value=\"name desc\" class=\"sort-option btn-text "
    + ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"sortOption") : depth0),"name desc",{"name":"equal","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":98},"end":{"line":45,"column":147}}})) != null ? stack1 : "")
    + "\">\n                                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"name") : stack1)) != null ? lookupProperty(stack1,"desc") : stack1), depth0))
    + "  <span class=\"m-l-20\"><i class=\"bbico bbico-check\"></i></span>\n                                        </button></li>\n                                        <li><button value=\"contentDatePublished asc\" class=\"sort-option btn-text "
    + ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"sortOption") : depth0),"contentDatePublished asc",{"name":"equal","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":113},"end":{"line":48,"column":177}}})) != null ? stack1 : "")
    + "\">\n                                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"publicationDate") : stack1)) != null ? lookupProperty(stack1,"desc") : stack1), depth0))
    + "  <span class=\"m-l-20\"><i class=\"bbico bbico-check\"></i></span>\n                                        </button></li>\n                                        <li><button value=\"contentDatePublished desc\" class=\"sort-option btn-text "
    + ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"sortOption") : depth0),"contentDatePublished desc",{"name":"equal","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":114},"end":{"line":51,"column":179}}})) != null ? stack1 : "")
    + "\">\n                                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"publicationDate") : stack1)) != null ? lookupProperty(stack1,"asc") : stack1), depth0))
    + "  <span class=\"m-l-20\"><i class=\"bbico bbico-check\"></i></span>\n                                        </button></li>\n                                    </ul>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </nav>\n        </div>\n    </div>\n</div>\n";
},"useData":true});